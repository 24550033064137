import Link from '@/atoms/Link/Link'
import Image from 'next/image'

import { ReactComponent as ArrowLeft } from 'public/assets/icons/chevron-right.svg'

function Resources() {
  return (
    <section className="text-white bg-gradient-to-b from-[#52188D] to-[#0C0644]">
      <div className="container | py-10 md:py-18">
        <h2 className="font-heading font-semibold text-3xl md:text-5xl | max-w-col-8 mb-9 md:mb-14">
          Resources
        </h2>

        <div className="flex justify-center xl:justify-start flex-wrap xl:flex-nowrap gap-7 | mb-7">
          <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-14 bg-gradient-to-b from-[#AD71FF] to-[#501A9B] | rounded-xl relative group overflow-hidden max-w-xs md:max-w-[598px] lg:max-w-[571px]">
            <div className="pt-7 pb-4 px-11 md:pl-8 md:pr-0 md:py-5 z-10 relative">
              <h3 className="font-heading text-2xl font-semibold | h-8 md:h-12 mb-5 md:mb-6">
                Ebooks
              </h3>
              <p className="text-base mb-5 md:mb-8">
                Explore in-depth guides on content marketing, SEO, design, and
                more.
              </p>
              <Link
                href="/resources/?type=ebook"
                target="_blank"
                className="flex items-center underline underline-offset-8"
              >
                Know More
                <ArrowLeft className="ml-2" />
              </Link>
            </div>
            <Image
              src="/assets/homePage/images/resources/ebook-resource.png"
              alt=""
              width={270}
              height={228}
              className="hidden md:block"
            />
            <Image
              src="/assets/homePage/images/resources/ebook-resource-mob.png"
              alt=""
              width={270}
              height={228}
              className="block md:hidden w-full"
            />
            <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#AD71FF] transition-all rounded-xl"></div>
          </div>

          <div className="relative group overflow-hidden">
            <div className="bg-gradient-to-b from-[#17acc4e6] to-[#10708069] rounded-xl p-11 md:py-5 md:px-8 z-10 relative max-w-xs h-full flex flex-col justify-between">
              <div className="flex justify-between mb-5 md:mb-4">
                <h3 className="font-heading text-2xl font-semibold h-14 w-44 md:w-32">
                  Case Studies
                </h3>
                <div>
                  <Image
                    src="/assets/homePage/images/resources/case-study-resource.svg"
                    alt=""
                    width={40}
                    height={40}
                  />
                </div>
              </div>
              <p className="text-base mb-10 md:mb-8 max-w-[220px]">
                Check out how Pepper Content drove its customers to success.
              </p>
              <Link
                href="/case-study/"
                target="_blank"
                className="flex items-center underline underline-offset-4"
              >
                Know More
                <ArrowLeft className="ml-2" />
              </Link>
            </div>
            <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#17acc4e6] transition-all rounded-xl"></div>
          </div>

          <div className="relative group overflow-hidden">
            <div className="bg-gradient-to-b from-[#E67B5A] to-[#ffe7e033] rounded-xl p-11 md:py-5 md:px-8 z-10 relative max-w-xs h-full flex flex-col justify-between">
              <div className="flex justify-between | mb-5 md:mb-4">
                <h3 className="font-heading text-2xl font-semibold h-14 md:w-32">
                  Blogs
                </h3>
                <div>
                  <Image
                    src="https://pct-strapi-images-bucket-dev.s3.ap-south-1.amazonaws.com/blogs_resource_d41414d301.svg"
                    alt=""
                    width={40}
                    height={40}
                    className=""
                  />
                </div>
              </div>
              <p className="text-base mb-10 md:mb-8 max-w-[220px]">
                Get insights on the latest trends in the marketing industry.
              </p>
              <Link
                href="/blog/"
                target="_blank"
                className="flex items-center underline underline-offset-4"
              >
                Know More
                <ArrowLeft className="ml-2" />
              </Link>
            </div>
            <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#E67B5A] transition-all rounded-xl"></div>
          </div>
        </div>

        <div className="flex justify-center xl:justify-start flex-wrap xl:flex-nowrap gap-7">
          <div className="relative group overflow-hidden">
            <div className="bg-gradient-to-b from-[#1C9E58] to-[#84ffbd33] rounded-xl p-11 md:py-5 md:px-8 z-10 relative max-w-xs h-full flex flex-col justify-between">
              <div className="flex justify-between | mb-5 md:mb-4">
                <h3 className="font-heading text-2xl font-semibold h-14 md:w-32">
                  Help Center
                </h3>
                <div>
                  <Image
                    src="/assets/homePage/images/resources/help-center-resource.svg"
                    alt=""
                    width={40}
                    height={40}
                    className=""
                  />
                </div>
              </div>
              <p className="text-base mb-10 md:mb-8 max-w-[220px]">
                Learn about the Creator Platform: how-tos, FAQs, more.
              </p>
              <Link
                href="https://sixth-crocus-23f.notion.site/Guides-by-Usecase-09add85d608f47908227f23c758c4afa"
                target="_blank"
                className="flex items-center underline underline-offset-4"
              >
                Know More
                <ArrowLeft className="ml-2" />
              </Link>
            </div>
            <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#1C9E58] transition-all rounded-xl"></div>
          </div>

          <div className="relative group overflow-hidden">
            <div className="bg-gradient-to-b from-[#EF5196] to-[#ffadd14d] rounded-xl p-11 md:py-5 md:px-8 z-10 relative max-w-xs h-full flex flex-col justify-between">
              <div className="flex justify-between | mb-5 md:mb-4">
                <h3 className="font-heading text-2xl font-semibold h-14 w-44 md:w-32">
                  Creator Support
                </h3>
                <div>
                  <Image
                    src="/assets/homePage/images/resources/creator-resource.svg"
                    alt=""
                    width={40}
                    height={40}
                    className=""
                  />
                </div>
              </div>
              <p className="text-base mb-10 md:mb-8 max-w-[220px]">
                From how-to&apos;s to FAQs, learn more about the Creator
                Platform.
              </p>
              <Link
                href="https://peppercontent.freshdesk.com/support/home"
                target="_blank"
                className="flex items-center underline underline-offset-4"
              >
                Know More
                <ArrowLeft className="ml-2" />
              </Link>
            </div>
            <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#EF5196] transition-all rounded-xl"></div>
          </div>

          <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-14 bg-gradient-to-b from-[#CDA73B] to-[#ffd96c4d] | rounded-xl relative group overflow-hidden max-w-xs md:max-w-[598px] lg:max-w-[571px]">
            <div className="pt-7 pb-4 px-8 md:pl-8 md:pr-0 md:py-5 z-10 relative">
              <h3 className="font-heading text-2xl font-semibold | h-8 md:h-12 mb-5 md:mb-6">
                Events
              </h3>
              <p className="mb-5 md:mb-4 md:h-22">
                Check out the upcoming curation of webinars, in-person events,
                and more.
              </p>
              <Link
                href="/events/"
                target="_blank"
                className="flex items-center underline underline-offset-4"
              >
                Know More
                <ArrowLeft className="ml-2" />
              </Link>
            </div>

            <Image
              src="/assets/homePage/images/resources/events-resource-mob.png"
              alt=""
              width={270}
              height={228}
              className="block md:hidden w-full rounded-br-xl"
            />
            <Image
              src="/assets/homePage/images/resources/events-resource-desk.png"
              alt=""
              width={270}
              height={228}
              className="hidden md:block rounded-tr-xl rounded-br-xl"
            />
            <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#CDA73B] transition-all rounded-xl"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Resources
