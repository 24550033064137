import React from 'react'
import clsx from 'clsx'
import Fuse from 'fuse.js'

import SelectSearch from 'react-select-search/dist/cjs'
import { SelectSearchProps, SelectSearchOption } from 'react-select-search'
import { useField } from 'formik'

import styles from './select.module.css'
import styleDark from './selectDark.module.css'

interface Props extends SelectSearchProps {
  className?: string
  label?: string
  labelClass?: string
  name: string
  required?: boolean
  placeholder?: string
  isDark?: boolean
  isSearch?: boolean
}

const SelectField = ({
  name,
  className,
  label,
  labelClass,
  required,
  isDark = false,
  placeholder,
  isSearch,
  ...props
}: Props) => {
  const [field, meta, helpers] = useField<string | undefined>(name)

  const fuzzySearch = (options: SelectSearchOption[]) => {
    const searchOptions =
      options?.[0].type === 'group' ? options.flatMap(i => i.items) : options

    const fuse = new Fuse(searchOptions, {
      keys: ['name', 'value'],
      threshold: 0.3
    })

    return (value: string) => {
      if (!value.length) return options
      return fuse.search(value)
    }
  }

  return (
    <div className={clsx('select__container', className)}>
      {label && (
        <label
          className={clsx(
            labelClass,
            'block  | text-gray-80 font-medium  |  mb-2'
          )}
          style={{ fontSize: 13 }}
          htmlFor={`form-${name}`}
        >
          <span>{label}</span>
          {required && <span className="text-orange-50">&nbsp;*</span>}
        </label>
      )}
      <SelectSearch
        id={`form-${name}`}
        placeholder={placeholder}
        value={field.value}
        search={isSearch}
        filterOptions={fuzzySearch}
        onBlur={() => helpers.setTouched(true)}
        onChange={(value: string) => {
          setTimeout(() => {
            helpers.setTouched(true)
          })
          helpers.setValue(value)
        }}
        className={(key: string) => {
          if (key === 'container' && props.search)
            return isDark ? styleDark[`search_${key}`] : styles[`search_${key}`]

          return isDark
            ? `${styleDark[key]} select__container__${key}`
            : `${styles[key]} select__container__${key}`
        }}
        {...props}
      />

      {meta.touched && meta.error ? (
        <div className="text-small font-medium text-orange-50  |  mt-2">
          {meta.error}
        </div>
      ) : null}
    </div>
  )
}

export default SelectField
