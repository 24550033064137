/* blocks */
import PageHero from './components/PageHero/PageHero'
import CustomerLogo from '@/cmp/organism/CustomerLogo/CustomerLogo'
import AiPoweredPlatform from './components/AiPoweredPlatform/AiPoweredPlatform'
import ContentStrategy from './components/ContentStrategy/ContentStrategy'
import AiPoweredTechnology from './components/AiPoweredTechnology/AiPoweredTechnology'
import ExpertLedTalent from './components/ExpertLedTalent/ExpertLedTalent'
import UseCase from './components/UseCase/UseCase'
import HomePepperAdvantage from './components/PepperAdvantage/PepperAdvantage'
import Testimonial from './components/Testimonial/Testimonial'
import Resources from './components/Resources/Resources'
import BookADemo from './components/BookADemo/BookADemo'

/* utils */
import { NewHomePageAttributes } from '@/utils/queries/new-homepage'

interface Props {
  data: NewHomePageAttributes
}

function HomePageTemplate({ data }: Props): JSX.Element {
  return (
    <>
      <PageHero
        primaryHeadline={data.hero.title}
        subText={data.hero.description}
        primaryCta={data.hero.primaryCta}
        image={data.hero.image?.data?.attributes}
      />

      {data.brandList.brands.data.length > 0 ? (
        <CustomerLogo
          title={data.brandList.title}
          brands={data.brandList.brands.data.map(i => ({
            ...i.attributes.logo.data.attributes,
            alternativeText: i.attributes.name
          }))}
          awards={homepageData.awards.imageList.map(item => ({
            url: item.attributes.url,
            alternativeText: item.attributes.alternativeText ?? '',
            width: item.attributes.width,
            height: item.attributes.height
          }))}
          mode="purple"
        />
      ) : null}

      {data.aiPoweredPlatform.multimedia?.media?.data?.attributes ? (
        <AiPoweredPlatform
          title={data.aiPoweredPlatform.title ?? ''}
          description={data.aiPoweredPlatform.description ?? ''}
          color={data.aiPoweredPlatform.color ?? ''}
          image={data.aiPoweredPlatform.multimedia.media.data.attributes}
        />
      ) : null}

      <ContentStrategy
        list={homepageData.featureListDropdown.map(i => {
          return {
            id: i.id,
            title: i.title ?? '',
            description: i.description ?? '',
            media: i.multimedia?.media?.data?.attributes,
            bgColor: i.color ?? ''
          }
        })}
      />

      <AiPoweredTechnology />
      <ExpertLedTalent />
      <UseCase />
      <HomePepperAdvantage />
      <section className="container">
        <hr className="text-[#7566FF] mb-10 md:mb-18" />
      </section>
      <Testimonial />
      <Resources />
      <BookADemo />
    </>
  )
}

// TODO: Update featureListDropdown ctaLinks after the new pages are developed
const homepageData = {
  awards: {
    title: `A trusted partner in <br/> 2500+ growth stories`,
    imageList: [
      {
        attributes: {
          url: '/assets/homePage/images/awards/easiestToUse.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        attributes: {
          url: '/assets/homePage/images/awards/highPerformer.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        attributes: {
          url: '/assets/homePage/images/awards/leaderWinter.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        attributes: {
          url: '/assets/homePage/images/awards/leaderSmallBusiness.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        attributes: {
          url: '/assets/homePage/images/awards/top100.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      },
      {
        attributes: {
          url: '/assets/homePage/images/awards/momentumLeader.png',
          alternativeText: 'alt',
          width: 118,
          height: 153
        }
      }
    ]
  },
  featureListDropdown: [
    {
      id: '640',
      title: '<p>Conducting market research</p>',
      description:
        '<p>We gather valuable insights directly from consumers by conducting thorough research.</p>',
      multimedia: {
        media: {
          data: {
            attributes: {
              url: '/assets/homePage/images/services/content-strategy-img-1.png',
              alternativeText: '',
              width: 548,
              height: 475
            }
          }
        }
      },
      color:
        'linear-gradient(130deg, #00DBFF -21.03%, rgba(0, 219, 255, 0.51) -6.39%, rgba(0, 219, 255, 0.00) 79.2%)'
      // ctaLink: '/features/optimised-content-creation/'
    },
    {
      id: '641',
      title: '<p>Crafting a unique proposition</p>',
      description:
        '<p>We develop a distinctive proposition designed to set your brand apart from the competition.</p>',
      multimedia: {
        media: {
          data: {
            attributes: {
              url: '/assets/homePage/images/services/content-strategy-img-2.png',
              alternativeText: '',
              width: 1081,
              height: 1140
            }
          }
        }
      },
      color:
        'linear-gradient(130deg, #00DBFF -21.03%, rgba(0, 219, 255, 0.51) -6.39%, rgba(0, 219, 255, 0.00) 79.2%)'
      // ctaLink: '/features/optimised-content-creation/'
    },
    {
      id: '642',
      title: '<p>Future-back strategy development</p>',
      description:
        '<p>Employing a future-back approach, we strategize to bring the campaign proposition to life.</p>',
      multimedia: {
        media: {
          data: {
            attributes: {
              url: '/assets/homePage/images/services/content-strategy-img-4.png',
              alternativeText: '',
              width: 1081,
              height: 1140
            }
          }
        }
      },
      color:
        'linear-gradient(130deg, #00DBFF -21.03%, rgba(0, 219, 255, 0.51) -6.39%, rgba(0, 219, 255, 0.00) 79.2%)'
      // ctaLink: '/features/optimised-content-creation/'
    },
    {
      id: '643',
      title: '<p>Measuring success at each step</p>',
      description:
        '<p>The campaign&apos;s success is continuously measured at each step to gauge its impact and effectiveness.</p>',
      multimedia: {
        media: {
          data: {
            attributes: {
              url: '/assets/homePage/images/services/content-strategy-img-3.png',
              alternativeText: '',
              width: 1081,
              height: 1140
            }
          }
        }
      },
      color:
        'linear-gradient(130deg, #00DBFF -21.03%, rgba(0, 219, 255, 0.51) -6.39%, rgba(0, 219, 255, 0.00) 79.2%)'
      // ctaLink: '/features/optimised-content-creation/'
    }
  ]
}

export default HomePageTemplate
