import { GetStaticProps } from 'next'

import Layout from '@/cmp/templates/Layout/Layout'
import HomePageTemplate from '@/cmp/templates/Homepage/Homepage'

import {
  NewHomePageAttributes,
  NewHomePageResponse,
  newHomePageQuery
} from '@/utils/queries/new-homepage'
import { getStrapiGraphqlData } from '@/utils/api/wrapper'
import { TEN_MINS_REVALIDATION } from '@/utils/constants/revalidation'
import { organizationSchema } from '@/data/about_us'

interface Props {
  data: NewHomePageAttributes
}

const metadata = [
  {
    property: 'og:title',
    content: `Pepper Content - Your One-Stop Solution for Content Marketing`
  },
  {
    property: 'description',
    content: `Pepper Content is an AI-driven content marketing platform for CMOs, aiding businesses to create top-notch content and track its lifecycle. Streamline your content marketing, and achieve your business goals faster. Book a demo today.`
  },
  {
    property: 'og:description',
    content: `Pepper Content is an AI-driven content marketing platform for CMOs, aiding businesses to create top-notch content and track its lifecycle. Streamline your content marketing, and achieve your business goals faster. Book a demo today.`
  },
  {
    property: 'og:image',
    content: '/assets/homePage/images/og-image.png'
  }
]

function HomePage({ data }: Props): JSX.Element {
  return (
    <Layout
      title="Pepper Content - Your One-Stop Solution for Content Marketing"
      meta={metadata}
      canonical="/"
      jsonSchema={[organizationSchema]}
    >
      <HomePageTemplate data={data} />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const result = await getStrapiGraphqlData<NewHomePageResponse>(
    newHomePageQuery.loc?.source.body
  )

  return {
    props: {
      data: result?.newHomePage?.data.attributes
    },
    revalidate: TEN_MINS_REVALIDATION
  }
}

export default HomePage
