import clsx from 'clsx'
import { useRouter } from 'next/router'
import { Form, Formik, FormikHelpers } from 'formik'

/* blocks */
import Link from '@/atoms/Link/Link'
import Button from '@/atoms/Button/Button'
import TextField from '@/molecules/FormikFields/TextField'
import PhoneField from '@/molecules/FormikFields/PhoneField'
import SelectField from '@/molecules/FormikFields/SelectField'

/* utils */
import { numEmployeesOptions } from '@/utils/constants/form'
import countries from '@/utils/enums/countries'
import { FormValues, initialFormValues, validationSchema } from './schema'
import { useToast } from '@/hooks/useToast'
import { dataLayerFormSubmit } from '@/utils/functions/dataLayerEvents'

/* styling */
import styles from './_.module.css'

function BookADemoForm() {
  const router = useRouter()
  const { toast, showToast } = useToast()

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    try {
      const result = await submitToMailChimp(
        `/api/forms/hp-book-a-demo/`,
        values
      )

      if (!result.ok) {
        showToast({
          title: result.data.message,
          type: 'error',
          swipeDirection: 'up'
        })
        return
      }
      dataLayerFormSubmit({
        formName: 'HP Book A Demo Form',
        formValues: values
      })
      helpers.resetForm()
      router.push({
        pathname: `/thank-you/`,
        query: { param: 'hp-book-a-demo-form' }
      })
    } catch (e) {
      showToast({
        title: 'Please try again',
        type: 'error',
        swipeDirection: 'up'
      })
    }
  }

  const submitToMailChimp = async (url: string, values: FormValues) => {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        status: 'subscribed',
        sourceUrl: router.asPath
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const data = await response.json()
    return { ok: response.ok, data }
  }

  return (
    <>
      <Formik<FormValues>
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        <Form name="Homepage Book A Demo Form" id="book-a-demo-hp-form">
          <div className="md:flex md:gap-5">
            <TextField
              name="firstName"
              className={clsx('mb-5', styles.formField)}
              placeholder="First Name"
              required
            />
            <TextField
              name="lastName"
              className={clsx('mb-5', styles.formField)}
              placeholder="Last Name"
              required
            />
          </div>
          <TextField
            name="company"
            className={clsx('mb-5', styles.formField)}
            placeholder="Company Name"
            required
          />
          <SelectField
            name="numEmployees"
            placeholder="Number of Employees"
            options={numEmployeesOptions.map(item => ({
              name: item.name,
              value: item.value
            }))}
            className={clsx('relative mb-6', styles.formField)}
            required
          />
          <TextField
            name="email"
            className={clsx('mb-5', styles.formField)}
            placeholder="Email Address"
            required
          />
          <SelectField
            name="country"
            placeholder="Country"
            options={countries.map(country => ({
              name: country.name,
              value: country.name
            }))}
            className={clsx('relative mb-6', styles.formField)}
            required
          />
          <PhoneField
            name="phone"
            placeholder="9990000000"
            className="mb-4"
            required
          />
          <div>
            <Button
              className={clsx(
                'text-xl font-medium | !rounded | w-full mb-6',
                styles.registerBtn
              )}
              size="large"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
          <div className="text-caption md:text-small text-center text-white | max-w-col-3 md:max-w-col-5 mx-auto">
            By signing up you agree to our
            <Link
              href="/terms/#section-1"
              target="_blank"
              className="font-semibold underline underline-offset-4 mx-1"
            >
              Terms of Service
            </Link>
            and
            <Link
              href="/privacy/#section-1"
              target="_blank"
              className="font-semibold underline underline-offset-4 mx-1"
            >
              Privacy Policy
            </Link>
          </div>
        </Form>
      </Formik>
      {toast}
    </>
  )
}

export default BookADemoForm
