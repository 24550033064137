import { useEffect } from 'react'

interface Props {
  link: string
  handleClose: () => void
}

function VideoModal({ link, handleClose }: Props) {
  useEffect(() => {
    const handleCloseWithEsc = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        handleClose()
      }
    }

    window.addEventListener('keydown', handleCloseWithEsc)

    return () => {
      window.removeEventListener('keydown', handleCloseWithEsc)
    }
  }, [handleClose])

  return (
    <button
      tabIndex={-1}
      onClick={handleClose}
      className="fixed | w-full h-full top-0 left-0 | z-50"
    >
      <div className="bg-gray-90 | opacity-90 | absolute top-0 left-0 | w-full h-full" />
      <div className="max-width-container | relative | flex | w-full | mx-auto">
        <iframe
          src={link}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-[90%] aspect-video | mx-auto"
        />
      </div>
    </button>
  )
}

export default VideoModal
