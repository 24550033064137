import { CSSProperties, ReactNode } from 'react'
import clsx from 'clsx'

import { ReactComponent as LoaderIcon } from 'public/assets/icons/loader.svg'

import styles from './_.module.css'

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'pill'
  | 'orangePill'
  | 'yellowPill'
type ButtonSize = 'large' | 'default' | 'small'

interface Props {
  children?: ReactNode
  className?: string
  isActive?: boolean
  size?: ButtonSize
  type?: ButtonType
  icon?: ReactNode
  disabled?: boolean
  rounded?: boolean
  loading?: boolean
  onClick?: () => void | Promise<unknown>
  style?: CSSProperties
  htmlType?: 'button' | 'submit' | 'reset'
  [key: string]: unknown
}

const Button: React.FC<Props> = ({
  children,
  disabled = false,
  className = '',
  isActive,
  icon,
  size = 'default',
  type = 'primary',
  rounded = true,
  loading = false,
  htmlType = 'button',
  onClick,
  ...props
}) => {
  let buttonClassName = styles.button

  switch (type) {
    case 'primary':
      buttonClassName += ` ${styles.primary} `
      break
    case 'secondary':
      buttonClassName += ` ${styles.secondary} `
      break
    case 'tertiary':
      buttonClassName += ` ${styles.tertiary} `
      break
    case 'pill':
      buttonClassName += ` ${styles.pill} `
      break
    case 'orangePill':
      buttonClassName += ` ${styles.orangePill} `
      break
    case 'yellowPill':
      buttonClassName += ` ${styles.yellowPill} `
      break
  }

  switch (size) {
    case 'large':
      buttonClassName += `${styles.large} `
      break
    case 'default':
      buttonClassName += `${styles.standard} `
      break
    case 'small':
      buttonClassName += `${styles.small} `
      break
  }

  if (rounded) buttonClassName += `${styles.rounded} `

  return (
    <button
      onClick={onClick}
      className={clsx(
        'font-medium',
        buttonClassName,
        isActive && 'active',
        (icon || loading) && 'inline-flex justify-center items-center',
        className
      )}
      disabled={disabled || loading}
      type={htmlType}
      {...props}
    >
      <span>{children}</span>
      {icon && <div className="ml-2">{icon}</div>}
      {loading && <LoaderIcon className={clsx(styles.loader, 'ml-2')} />}
    </button>
  )
}

export default Button
