import Image from 'next/image'

import BookADemoForm from './BookADemoForm/BookADemoForm'

function BookADemo() {
  return (
    <section className="bg-gradient-to-b from-[#1400D5] to-[#2F19FF] text-white">
      <div className="container | py-10 md:py-18">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="max-w-md">
            <h2 className="text-3xl md:text-5xl font-semibold capitalize | mb-6 md:mb-8">
              Book a demo
            </h2>
            <div className="text-lg md:text-xl | mb-11 md:mb-8">
              See what Pepper can do for you in a short 20 mins call and
              discover the new way of doing content marketing.
            </div>
            <Image
              src="/assets/homePage/images/book-a-demo.png"
              alt=""
              width={424}
              height={272}
              className="hidden md:block"
            />
          </div>
          <div className="mb-8 md:mb-0 text-black">
            <BookADemoForm />
          </div>
          <Image
            src="/assets/homePage/images/book-a-demo.png"
            alt=""
            width={424}
            height={272}
            className="block md:hidden"
          />
        </div>
      </div>
    </section>
  )
}

export default BookADemo
