import { getDefaultPhoneCode } from '@/utils/functions/defaultPhoneCode'
import { isValidWorkEmail, phoneValidationSchema } from '@/utils/functions/form'
import { object, string } from 'yup'

export type FormValues = {
  firstName: string
  lastName: string
  company: string
  numEmployees: string
  email: string
  country: string
  phone: {
    extension: string
    number: string
  }
}

export const initialFormValues = {
  firstName: '',
  lastName: '',
  company: '',
  numEmployees: '',
  email: '',
  country: '',
  phone: {
    extension: getDefaultPhoneCode(),
    number: ''
  }
}

export const validationSchema = object().shape({
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
  company: string().required('Please enter your company name'),
  numEmployees: string().required('Please select company size'),
  email: string()
    .email('Please enter work email address')
    .test('email', 'Please enter your work email address', value =>
      isValidWorkEmail(value ?? '')
    )
    .required('This field is required'),
  phone: phoneValidationSchema,
  country: string().required('Please select your country')
})
