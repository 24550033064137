import { lazy, object, string } from 'yup'
import { getDefaultPhoneCode } from './defaultPhoneCode'

const requiredFieldMessage = 'This field is required'
const invalidPhoneNumberMessage = 'Phone number is not valid'

export function isValidWorkEmail(email: string): boolean {
  const simpleEmailValidationRegex = /\S+@\S+\.\S+/
  const isValidEmail = simpleEmailValidationRegex.test(email)

  if (!isValidEmail) return false

  const isValidWorkEmail =
    email
      .toLowerCase()
      .match(
        /^[a-zA-Z0-9_.+-]+@(gmail|yahoo|outlook|msn|rediff|hotmail|live|ymail|iCloud|protonmail).(.*)$/
      ) === null

  return isValidWorkEmail
}

export type CommonFormValues = {
  firstName: string
  lastName: string
  email: string
  phone: {
    extension: string
    number: string
  }
  company: string
  numEmployees: string
}

export const commonFormInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: {
    extension: getDefaultPhoneCode(),
    number: ''
  },
  company: '',
  numEmployees: ''
}

export const phoneValidationSchema = lazy(value => {
  switch (value.extension) {
    case '+1':
      return object().shape({
        extension: string(),
        number: string()
          .matches(
            /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/,
            invalidPhoneNumberMessage
          )
          .required(requiredFieldMessage)
      }) // schema for object
    case '+91':
      return object().shape({
        extension: string(),
        number: string()
          .matches(/^[5-9]\d{9}$/, invalidPhoneNumberMessage)
          .required(requiredFieldMessage)
      }) // schema for string
    default:
      return object().shape({
        extension: string(),
        number: string()
          .required(requiredFieldMessage)
          .matches(/^[0-9-+ ]+$/, invalidPhoneNumberMessage)
      }) // here you can decide what is the default
  }
})

export const commonFormValidationSchema = object().shape({
  firstName: string()
    .matches(/^\S+$/, 'Space characters are not allowed')
    .max(64, 'First Name cannot be more than 64 characters')
    .required('First name is required'),
  lastName: string()
    .matches(/^\S+$/, 'Space characters are not allowed')
    .max(64, 'Last Name cannot be more than 64 characters')
    .required('Last name is required'),
  email: string()
    .email('Enter valid email address')
    .matches(
      /^[a-zA-Z0-9_.+-]+@(?!gmail|yahoo|outlook|msn|rediff|hotmail|live|ymail|iCloud|protonmail).(.*)$/,
      'Please enter your valid work email'
    )
    .required('Please enter your email address'),
  phone: phoneValidationSchema,
  company: string().required('Please enter company name'),
  numEmployees: string().required('Please select company size')
})
