export const organizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Pepper Content',
  alternateName: 'Pepper Content Pvt. Ltd.',
  url: 'https://www.peppercontent.io/',
  logo: 'https://assets.peppercontent.dev/icons/PepperLogo.svg',
  sameAs: [
    'https://www.facebook.com/Pepper-Co-322356101506651/',
    'https://twitter.com/Pepper_Content',
    'https://www.instagram.com/pepper_content/',
    'https://www.linkedin.com/company/peppercontent/'
  ]
}
