import Link from '@/atoms/Link/Link'
import Image from 'next/image'

import { ReactComponent as ArrowRight } from 'public/assets/icons/chevron-right.svg'

interface Props {
  title: string
  description?: string
  color?: string
  image: StrapiImageFile
  cta?: StrapiLinkSharedComponent | null
}

function AiPoweredPlatform({ title, description, color, image, cta }: Props) {
  return (
    <section className="font-body text-white" style={{ background: color }}>
      <div className="container | py-10 md:py-18">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
          <div className="md:col-span-5 text-left">
            <h2
              className="font-heading text-4xl font-semibold lg:text-5xl tracking-tight | mb-4 md:mb-10"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {description ? (
              <div
                className="text-lg | mb-9"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            ) : null}
            {cta ? (
              <Link
                href={cta.href}
                className="flex items-center underline underline-offset-8 | mb-14 md:mb-0"
              >
                {cta.title}
                <ArrowRight className="ml-2" />
              </Link>
            ) : null}
          </div>
          <div className="md:col-start-7 md:col-end-13 relative | m-auto">
            {image ? (
              <Image
                src={image.url}
                alt={image.alternativeText ?? ''}
                width={image.width}
                height={image.height}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AiPoweredPlatform
