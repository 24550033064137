import { useEffect, useRef } from 'react'
import Image from 'next/image'

/* styles */
import styles from './_.module.css'

function ExpertLedTalent() {
  const contentSectionRef = useRef<HTMLDivElement | null>(null)
  const animatedLineRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Set the height of the animatedLineRef to 100% when intersecting
            if (animatedLineRef.current) {
              animatedLineRef.current?.classList.add(styles.sideLineFadeIn)
              animatedLineRef.current?.classList.remove(styles.sideLineFadeOut)
            }
          } else {
            // Set the height of the animatedLineRef to 0 when not intersecting
            animatedLineRef.current?.classList.add(styles.sideLineFadeOut)
            animatedLineRef.current?.classList.remove(styles.sideLineFadeIn)
          }
        })
      },
      {
        rootMargin: '200px 0px -200px 0px'
      }
    )

    if (animatedLineRef.current) {
      observer.observe(animatedLineRef.current)
    }

    return () => {
      if (animatedLineRef.current) {
        observer.unobserve(animatedLineRef.current)
      }
    }
  }, [animatedLineRef])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // contentSectionRef.current?.classList.add(styles.fadeIn) // Add the fadeIn class
            contentSectionRef.current?.classList.remove(styles.fadeOut) // Remove fadeOut class if present
            contentSectionRef.current?.classList.add(styles.fadeIn)
          } else {
            // contentSectionRef.current?.classList.remove(styles.fadeIn) // Remove fadeIn class if not intersecting
            contentSectionRef.current?.classList.remove(styles.fadeIn) // Remove fadeIn class if not intersecting
            contentSectionRef.current?.classList.add(styles.fadeOut) // Add the fadeOut class
          }
        })
      },
      {
        rootMargin: '200px 0px -200px 0px'
      }
    )

    if (contentSectionRef.current) {
      observer.observe(contentSectionRef.current)
    }

    return () => {
      if (contentSectionRef.current) {
        observer.unobserve(contentSectionRef.current)
      }
    }
  }, [contentSectionRef])

  useEffect(() => {
    const handleAnimationEnd = () => {
      // Reset overflow property after animation ends
      contentSectionRef.current?.style &&
        (contentSectionRef.current.style.overflow = '')
    }

    const fadeInElement = contentSectionRef.current
    fadeInElement?.addEventListener('animationend', handleAnimationEnd)

    return () => {
      fadeInElement?.removeEventListener('animationend', handleAnimationEnd)
    }
  }, [])

  return (
    <section className="bg-blue-web-90 text-white">
      <div className="container | pb-10 md:pb-18">
        <div className="flex items-center gap-3">
          <div className="flex flex-col items-center basis-1/12">
            <Image
              src="/assets/homePage/images/services/expert-talent-icon.svg"
              alt=""
              width={45}
              height={45}
              className="w-11 h-11"
            />
          </div>
          <div className="text-purple-30 font-semibold | basis-full">
            Expert-Led Talent
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex flex-col items-center basis-1/12 flex-shrink-0">
            <div
              ref={animatedLineRef}
              className="aminatedLine w-[2px] h-full rounded-md js-build-in-item build-in-scale-top transform scale-100 | mx-auto"
              style={{
                background:
                  'linear-gradient(180deg, #070425 0%, #BB8AFF 28.7%, #B78EF0 75.39%, #070425 100%)',
                transition: 'transform 0.5s ease'
              }}
              data-build-delay="500"
            />
          </div>

          <div className="basis-full overflow-x-hidden | mb-18">
            <h2 className="font-heading text-3xl md:text-5xl text-white font-semibold | max-w-col-8 pt-7 mb-5">
              Limitless creativity, powered by experts
            </h2>
            <div className="text-sm md:text-xl | mb-14">
              Get matched with the best-suited creators, as per your industry
              and use case
            </div>
            <div ref={contentSectionRef}>
              <Image
                src="/assets/homePage/images/services/expert-led-talent.png"
                alt=""
                width={1095}
                height={710}
                className="hidden sm:block"
              />
              <Image
                src="/assets/homePage/images/services/expert-led-talent-mob.png"
                alt=""
                width={278}
                height={553}
                className="w-full block sm:hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpertLedTalent
