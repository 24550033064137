import Image from 'next/image'
import React, { useState } from 'react'

/* blocks */
import Link from '@/atoms/Link/Link'
import VideoModal from '@/atoms/Modal/VideoModal'

/* utils */
import { dataLayerPostClickEvent } from '@/utils/functions/dataLayerEvents'

/* assets */
import { ReactComponent as ChevronRightIcon } from 'public/assets/icons/feather/arrow-left.svg'

function Testimonial() {
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [videoLink, setVideoLink] = useState('')

  const handleVideoButtonClick = (url: string) => {
    setVideoLink(url)
    setShowVideoModal(true)
  }
  return (
    <section>
      <div className="container | mb-10 md:mb-18">
        <h2 className="font-heading font-semibold text-3xl md:text-5xl text-blue-web-90 | max-w-col-8 mb-10 lg:mb-12">
          The world&apos;s top marketing teams use Pepper
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 md:gap-5 lg:gap-8 | mb-6 md:mb-5 lg:mb-8">
          <div className="md:col-span-7 flex flex-col justify-between bg-gradient-to-b from-[#fff0c366] to-[#FFEAAD] rounded-xl | px-5 py-6 lg:px-9 lg:py-12">
            <div className="font-heading text-lg md:text-xl lg:text-2xl xl:text-3xl text-blue-web-90 opacity-60 | mb-12">
              “Pepper is helping us with really great content across the entire
              United States & create local content, for all of these types of
              destinations. So the scaling capabilities for authentic content at
              scale are truly wonderful.”
            </div>
            <div className="flex items-center mt-auto">
              <Image
                src="/assets/homePage/icons/testimonial-1.png"
                alt=""
                width={68}
                height={68}
                className="w-8 h-8 md:w-10 md:h-10 lg:w-18 lg:h-18 | rounded-[4px] lg:rounded-md"
              />
              <div className="flex-col ml-3 sm:ml-4 md:ml-7 mr-auto">
                <div className="text-sm md:text-xl font-medium text-blue-web-90 | mb-2">
                  Martijn Scheijbeler
                </div>
                <div className="text-caption md:text-sm text-blue-web-90">
                  SVP Marketing, RVshare
                </div>
              </div>
              <button onClick={() => handleVideoButtonClick('BpyqNTefvAY')}>
                <Image
                  src="/assets/homePage/icons/circular-play-dark.svg"
                  alt=""
                  width={70}
                  height={70}
                  className="w-8 h-8 lg:w-18 md:h-18"
                />
              </button>
              {showVideoModal && (
                <VideoModal
                  link={`https://www.youtube.com/embed/${videoLink}`}
                  handleClose={() => setShowVideoModal(false)}
                />
              )}
            </div>
          </div>
          <div className="md:col-start-8 md:col-end-13 flex flex-col justify-between bg-gradient-to-b from-[#dac0fe4d] to-[#D9BEFF] rounded-xl | p-3 lg:p-5">
            <Link
              href={`/case-study/cred/`}
              onClick={() =>
                dataLayerPostClickEvent({
                  title:
                    'How CRED partnered with Pepper Content to scale their product listings that drove app conversions',
                  type: 'case-study',
                  url: `/case-study/cred/}`
                })
              }
              className="bg-white font-heading | flex flex-col | rounded-2xl"
            >
              <div className="relative h-full">
                <Image
                  src="/assets/homePage/images/testimonial/CRED_rectangle_thumbnail.png"
                  alt="Cred Bg Image"
                  height={684}
                  width={537}
                  className="w-full object-cover rounded-2xl"
                />
                <div className="text-white | absolute bottom-0 | h-full w-full p-5 md:p-7">
                  <div className="h-22 sm:h-16 md:h-22 xl:h-36">
                    <Image
                      src="/assets/homePage/images/testimonial/cred-logo-light.png"
                      alt="Cred Logo"
                      height={51}
                      width={160}
                      className="w-28 md:w-[118px] lg:w-32"
                    />
                  </div>
                  <h2 className="text-xl md:text-base lg:text-2xl font-semibold | md:max-w-[200px] lg:max-w-xs lg:line-clamp-4 md:h-18 lg:h-24 mb-5 lg:mb-4">
                    How Pepper Scaled CRED’s product listings that drove app
                    conversion
                  </h2>
                  <hr className="text-white | md:max-w-[200px] lg:max-w-xs mb-4 lg:mb-2" />
                  <div className="flex flex-col gap-10 sm:gap-14 md:gap-6 lg:gap-16 xl:gap-24">
                    <div className="flex gap-4 md:gap-6">
                      <div>
                        <p className="text-2xl lg:text-3xl font-semibold | md:mb-2">
                          250%
                        </p>
                        <p className="font-body text-[7px] lg:text-caption font-medium | h-7">
                          growth in organic traffic
                        </p>
                      </div>
                      <div>
                        <p className="text-2xl lg:text-3xl font-semibold | md:mb-2">
                          2000%
                        </p>
                        <p className="font-body text-[7px] lg:text-caption font-medium | h-7">
                          surge in app downloads
                        </p>
                      </div>
                    </div>
                    <p className="text-base font-semibold | flex items-center gap-2">
                      Read Case Study
                      <ChevronRightIcon className="rotate-180" />
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 md:gap-5 lg:gap-8">
          <div className="flex justify-center items-center md:col-span-4 bg-gradient-to-b from-[#b3f4ff66] to-[#8EEFFF] rounded-xl | p-3 lg:p-5">
            <div className="text-white font-heading | flex flex-col | relative rounded-2xl">
              <Image
                src="/assets/homePage/images/testimonial/rv_share_bg_img.png"
                alt=""
                width={293}
                height={283}
                className="relative | w-full h-full object-fill rounded-xl"
              />
              <Link
                href={`/case-study/rv-share`}
                className="absolute flex flex-col top-0 w-full h-full p-6"
                onClick={() =>
                  dataLayerPostClickEvent({
                    title:
                      'How RVshare, the world&apos;s first and largest RV rental marketplace, transformed their content journey',
                    type: 'case-study',
                    url: `/case-study/rv-share/}`
                  })
                }
              >
                <div className="h-16">
                  <Image
                    src="/assets/homePage/images/testimonial/rv_share_white_logo.png"
                    alt=""
                    width={272}
                    height={80}
                    className="w-18"
                  />
                </div>
                <h2 className="text-4xl font-semibold | mb-4">10K+</h2>
                <span className="font-semibold line-clamp-2 h-12 | mb-5">
                  content pieces delivered
                </span>
                <span className="text-lg font-semibold |  flex items-center gap-2 flex-shrink-0  |  mt-auto">
                  Read Case Study
                  <span className="text-white">
                    <ChevronRightIcon className="font-semibold rotate-180" />
                  </span>
                </span>
              </Link>
            </div>
          </div>
          <div className="md:col-start-5 md:col-end-13 flex flex-wrap md:flex-nowrap justify-between items-center bg-gradient-to-b from-[#acffd266] to-[#96FFC6] rounded-xl | p-5 lg:p-4">
            <div className="block md:hidden mx-auto">
              <button onClick={() => handleVideoButtonClick('JE6f_SfmLSI')}>
                <Image
                  src="/assets/homePage/images/testimonial/image-with-play-mob.png"
                  alt="Cred Bg Image"
                  height={332}
                  width={280}
                  className="w-full object-cover mb-4"
                />
              </button>
              {showVideoModal && (
                <VideoModal
                  link={`https://www.youtube.com/embed/${videoLink}`}
                  handleClose={() => setShowVideoModal(false)}
                />
              )}
            </div>
            <div className="flex flex-col p-0 md:p-5 lg:p-10">
              <div className="font-heading text-sm md:text-small lg:text-2xl xl:text-xl text-blue-web-90 opacity-60 | max-w-col-4 mb-12 md:mb-6">
                “Pepper brings the entire team together. My content team, my SEO
                team, my brand team, CRM team, growth team. All of them have
                access to the platform. They exactly know what content is being
                created.”
              </div>

              <div className="flex items-center mt-auto">
                <div className="flex-col">
                  <div className="text-lg md:text-sm lg:text-xl font-semibold text-blue-web-90 | mb-2">
                    Himanshu Sirohi
                  </div>
                  <div className="text-xs md:text-caption lg:text-sm text-blue-web-90">
                    Head of Digital Marketing, Apollo 24/7
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <button onClick={() => handleVideoButtonClick('JE6f_SfmLSI')}>
                <Image
                  src="/assets/homePage/images/testimonial/image-with-play.png"
                  alt="Cred Bg Image"
                  height={332}
                  width={244}
                  className="md:w-full lg:h-full object-cover rounded-2xl"
                />
              </button>
              {showVideoModal && (
                <VideoModal
                  link={`https://www.youtube.com/embed/${videoLink}`}
                  handleClose={() => setShowVideoModal(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
