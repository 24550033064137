import { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import Link from '@/atoms/Link/Link'

/* assets */
import { ReactComponent as ArrowLeft } from 'public/assets/icons/chevron-right.svg'

/* styling */
import styles from './_.module.css'

type TabId = 'strategise' | 'create' | 'refresh' | 'analyse'

function AiPoweredTechnology(): JSX.Element {
  const [activeTab, setActiveTab] = useState<TabId>('strategise')
  const contentSectionRef = useRef<HTMLDivElement | null>(null)
  const animatedLineRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Set the height of the animatedLineRef to 100% when intersecting
            if (animatedLineRef.current) {
              animatedLineRef.current?.classList.add(styles.sideLineFadeIn)
              animatedLineRef.current?.classList.remove(styles.sideLineFadeOut)
            }
          } else {
            // Set the height of the animatedLineRef to 0 when not intersecting
            animatedLineRef.current?.classList.add(styles.sideLineFadeOut)
            animatedLineRef.current?.classList.remove(styles.sideLineFadeIn)
          }
        })
      },
      {
        rootMargin: '200px 0px -200px 0px'
      }
    )

    if (animatedLineRef.current) {
      observer.observe(animatedLineRef.current)
    }

    return () => {
      if (animatedLineRef.current) {
        observer.unobserve(animatedLineRef.current)
      }
    }
  }, [animatedLineRef])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            contentSectionRef.current?.classList.remove(styles.fadeOut) // Remove fadeOut class if present
            contentSectionRef.current?.classList.add(styles.fadeIn)
          } else {
            contentSectionRef.current?.classList.remove(styles.fadeIn) // Remove fadeIn class if not intersecting
            contentSectionRef.current?.classList.add(styles.fadeOut) // Add the fadeOut class
          }
        })
      },
      {
        rootMargin: '200px 0px -200px 0px'
      }
    )

    if (contentSectionRef.current) {
      observer.observe(contentSectionRef.current)
    }

    return () => {
      if (contentSectionRef.current) {
        observer.unobserve(contentSectionRef.current)
      }
    }
  }, [contentSectionRef])

  useEffect(() => {
    const handleAnimationEnd = () => {
      // Reset overflow property after animation ends
      contentSectionRef.current?.style &&
        (contentSectionRef.current.style.overflow = '')
    }

    const fadeInElement = contentSectionRef.current
    fadeInElement?.addEventListener('animationend', handleAnimationEnd)

    return () => {
      fadeInElement?.removeEventListener('animationend', handleAnimationEnd)
    }
  }, [])

  return (
    <section className="bg-blue-web-90">
      <div className="container">
        <div className="flex items-center gap-3">
          <div className="flex flex-col items-center basis-1/12">
            <Image
              src="/assets/homePage/images/services/ai-powered-technology-icon.svg"
              alt=""
              width={49}
              height={49}
              className="w-12 h-12"
            />
          </div>
          <div className="text-green-40 font-semibold | basis-full">
            AI-Powered Technology
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex flex-col items-center basis-1/12 flex-shrink-0">
            <div
              ref={animatedLineRef}
              className="aminatedLine w-[2px] h-full rounded-md js-build-in-item build-in-scale-top transform scale-100 | mx-auto"
              style={{
                background:
                  'linear-gradient(180deg, #070425 2.29%, #4DE593 22.4%, #BCF5D7 67.71%, #070425 100%)',
                transition: 'transform 0.5s ease'
              }}
              data-build-delay="500"
            />
          </div>

          <div className="basis-full overflow-x-hidden | mb-18">
            <h2 className="font-heading text-3xl md:text-5xl text-white font-semibold | max-w-col-8 pt-7 mb-14">
              Power your end-to-end organic marketing process with our powerful
              stack
            </h2>
            <div
              ref={contentSectionRef}
              className="border border-[#251F60] rounded-2xl bg-gradient-to-b from-[#251F60] to-[#251f6000] overflow-hidden | pt-6 pl-4 lg:pt-9 lg:pl-10"
            >
              <div className="relative">
                <div className="flex gap-6 md:gap-0 items-center justify-between whitespace-nowrap | overflow-x-auto pr-4 lg:pr-10 mb-8 md:mb-14">
                  {Object.values(tabs).map(tab => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id as TabId)}
                      className={clsx(
                        'flex items-center gap-2 md:gap-3 shrink-0 text-white | py-4 px-2',
                        styles.hoverBorder,
                        activeTab === tab.id ? styles.activeBorder : ''
                      )}
                    >
                      <Image
                        src={tab.icon}
                        alt=""
                        width={24}
                        height={24}
                        className="w-4 h-4 md:w-6 md:h-6"
                      />
                      <span className="font-heading font-semibold text-small md:text-lg">
                        {tab.title}
                      </span>
                    </button>
                  ))}
                </div>
              </div>

              <div
                key={tabs[activeTab].id}
                className={clsx(
                  'grid grid-cols-1 lg:grid-cols-2 gap-8',
                  styles.animate
                )}
              >
                <div>
                  <h3 className="font-heading text-2xl lg:text-3xl text-white font-semibold | max-w-col-4 mb-9">
                    {tabs[activeTab].heading}
                  </h3>
                  <ul className="text-lg text-gray-10 | space-y-6 mb-10">
                    {tabs[activeTab].list.map(item => (
                      <div key={item} className="flex md:items-center gap-3">
                        <Image
                          src="/assets/homePage/images/services/check-green-20-circular.svg"
                          alt=""
                          width={32}
                          height={32}
                          className="w-6 h-6 md:w-8 md:h-8"
                        />
                        <li className="text-small lg:text-base text-white opacity-70">
                          {item}
                        </li>
                      </div>
                    ))}
                  </ul>

                  <Link
                    href={tabs[activeTab].ctaLink}
                    className="text-gray-10 font-medium | inline-flex underline underline-offset-4 items-baseline gap-2"
                  >
                    Learn more about the product <ArrowLeft />
                  </Link>
                </div>

                <Image
                  src={tabs[activeTab].image}
                  alt=""
                  width={1159}
                  height={892}
                  className={clsx(styles.animateImage)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const tabs = {
  strategise: {
    id: 'strategise',
    title: 'Strategise',
    icon: '/assets/homePage/images/services/share-icon.svg',
    image: '/assets/homePage/images/services/ai-powered-strategies-image.png',
    heading: 'Outperform your competitors',
    list: [
      'Get theme and category recommendations',
      'Find best-suited keywords',
      'Get dozens of topic ideas'
    ],
    ctaLink: '/features/content-idea-lab/'
  },
  create: {
    id: 'create',
    title: 'Create',
    icon: '/assets/homePage/images/services/brush-square-icon.svg',
    image: '/assets/homePage/images/services/ai-powered-create-image.png',
    heading: 'Reach your target audience',
    list: [
      'Use SEO suggestions to optimize',
      'Writer faster with AI',
      'Check your content with real-time grading'
    ],
    ctaLink: '/features/content-editor/'
  },
  refresh: {
    id: 'refresh',
    title: 'Refresh',
    icon: '/assets/homePage/images/services/refresh-square-icon.svg',
    image: '/assets/homePage/images/services/ai-powered-refresh-image.png',
    heading: 'Revitalize your content',
    list: [
      'Identify dead pages',
      'Get optimization suggestions',
      'Improve keyword usage'
    ],
    ctaLink: '/features/content-audit/'
  },
  analyse: {
    id: 'analyse',
    title: 'Analysis and ROI',
    icon: '/assets/homePage/images/services/status-up-icon.svg',
    image: '/assets/homePage/images/services/ai-powered-analysis-roi-image.png',
    heading: 'Measure your content ROI',
    list: [
      `See your content's impact in numbers`,
      'Get detailed SEO reports',
      'Track engagement metrics'
    ],
    ctaLink: '/features/measure-content-roi/'
  }
}

export default AiPoweredTechnology
