import ToastDemo from '@/atoms/Toast/Toast'
import { useState, ReactElement } from 'react'

interface Props {
  type?: 'error' | 'success' | 'info'
  swipeDirection?: 'up' | 'down' | 'right'
  title: string
}

export const useToast = () => {
  const [toast, setToast] = useState<ReactElement>()

  const showToast = ({ title, type, swipeDirection }: Props) => {
    setToast(
      <ToastDemo
        key={Date.now()}
        title={title}
        type={type}
        swipeDirection={swipeDirection}
      />
    )
  }

  return { showToast, toast }
}
