import clsx from 'clsx'
import Image from 'next/image'

/* blocks */
import Link from '@/atoms/Link/Link'

interface Props {
  class?: string
  primaryHeadline: string
  heroBgColor?: string
  subText: string
  primaryCta: StrapiLinkSharedComponent
  secondaryCta?: StrapiLinkSharedComponent
  image?: {
    url: string
    alternativeText?: string
    mime: string
    height: number
    width: number
  }
}

function PageHero({
  primaryHeadline,
  subText,
  primaryCta,
  image,
  ...props
}: Props) {
  return (
    <section
      className={clsx(
        props.class,
        `text-white bg-gradient-to-b from-[#080522] to-[#341FFF] relative`
      )}
    >
      <div className="container | font-heading | text-left">
        <div className="block md:hidden">
          <Image
            src="/assets/homePage/images/human-hand.png"
            alt=""
            width={454}
            height={534}
            priority
          />
        </div>
        <div className="flex justify-between">
          <div className="max-w-col-5 my-auto">
            {primaryHeadline ? (
              <h1
                className="title | text-4xl md:text-3xl lg:text-6xl font-semibold tracking-tight | max-w-col-3 md:max-w-col-5 mb-4"
                dangerouslySetInnerHTML={{ __html: primaryHeadline }}
              />
            ) : null}
            {subText ? (
              <h2
                className="font-body text-sm md:text-lg lg:text-xl | mb-10 lg:mb-12"
                dangerouslySetInnerHTML={{ __html: subText }}
              />
            ) : null}
            <div className="font-body font-medium | flex flex-col sm:flex-row justify-center md:justify-start gap-4 | w-full">
              <Link
                href={primaryCta.href}
                className="font-medium bg-yellow-60 text-blue-web-90 text-xl | inline-flex items-center justify-center | rounded-md w-full md:w-80 max-h-14 py-4 px-6"
              >
                {primaryCta.title}
              </Link>
            </div>
          </div>
          <div className="hidden md:block">
            {image && (
              <Image
                className="md:max-w-sm lg:max-w-xl xl:max-w-2xl"
                src={image.url}
                alt={image.alternativeText ?? ''}
                width={image.width}
                height={image.height}
                priority
              />
            )}
          </div>
        </div>
        <div className="block md:hidden">
          <Image
            src="/assets/homePage/images/robot-hand.png"
            alt=""
            width={454}
            height={534}
            priority
          />
        </div>
      </div>
    </section>
  )
}

export default PageHero
