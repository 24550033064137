import Image from 'next/image'

const HomePepperAdvantage = () => {
  return (
    <section>
      <div className="container | py-10 md:py-18">
        <h2 className="font-heading font-semibold text-3xl md:text-5xl | mb-12 lg:mb-14">
          Get the Pepper Advantage
        </h2>
        <div className="blockGroup | grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 | py-6">
          <div className="rounded-lg | px-6 py-8 | flex flex-col md:grid md:grid-cols-1 md:grid-rows-[100px_1fr_12rem_auto] gap-7 | bg-[#FFE599]">
            <h3 className="font-heading font-medium text-3xl | max-w-col-3 md:mr-1">
              Scale content
            </h3>
            <p className="description | text-xl">
              Create content at scale with on-demand expertise and generative AI
            </p>
            <div className="illustration | relative w-full h-48">
              <Image
                src={'/assets/homePage/images/scale-content.svg'}
                alt=""
                fill
              />
            </div>
          </div>
          <div className="rounded-lg | px-6 py-8 | flex flex-col md:grid md:grid-cols-1 md:grid-rows-[100px_1fr_12rem_auto] gap-7 | bg-[#FFCCE2]">
            <h3 className="font-heading font-medium text-3xl | max-w-col-3 md:mr-1">
              Reduced CAC
            </h3>
            <p className="description | text-xl">
              Grow your organic presence and reduce dependence on paid channels
              with optimised content
            </p>
            <div className="illustration | relative w-full h-48">
              <Image
                src={'/assets/homePage/images/reduce-cac.svg'}
                alt=""
                fill
              />
            </div>
          </div>
          <div className="rounded-lg | px-6 py-8 | flex flex-col md:grid md:grid-cols-1 md:grid-rows-[100px_1fr_12rem_auto] gap-7 | bg-[#BCF5D7]">
            <h3 className="font-heading font-medium text-3xl | max-w-col-3 md:mr-1">
              Increase productivity
            </h3>
            <p className="description | text-xl">
              Bring your content, data, and SEO teams on a single platform that
              powers your content marketing lifecycle
            </p>
            <div className="illustration | relative w-full h-48">
              <Image
                src={'/assets/homePage/images/increase-productivity.svg'}
                alt=""
                fill
              />
            </div>
          </div>
          <div className="rounded-lg | px-6 py-8 | flex flex-col md:grid md:grid-cols-1 md:grid-rows-[100px_1fr_12rem_auto] gap-7 | bg-[#D4BBF6]">
            <h3 className="font-heading font-medium text-h3 | max-w-col-3 md:mr-1">
              Measure content RoI
            </h3>
            <p className="description | text-xl">
              Prove and improve your content RoI by using our AI-powered
              platform integrated with the best-in-class tools
            </p>
            <div className="illustration | relative w-full h-48">
              <Image
                src={'/assets/homePage/images/measure-roi.svg'}
                alt=""
                fill
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomePepperAdvantage
